import { useRouter } from 'next/router';
import { Flex, Box } from '@chakra-ui/react';
import { useContext } from 'react';
import AuthForm from '../../components/login/authForm';
import { NavigationContext } from '../../lib/contexts/NavigationContext';

const LoginPage = () => {
  const router = useRouter();

  const { redirectTo } = router.query;
  const { paths } = useContext(NavigationContext);

  function onSuccess(): void {
    if (typeof redirectTo === 'string') {
      router.push(redirectTo);
    } else {
      router.push(paths.root);
    }
  }

  return (
    <Flex
      w="100%"
      h="100%"
      justifyItems="center"
      alignItems="center"
    >
      <Box
        m="auto"
        p={6}
        bgColor="#ffffff"
        boxShadow="rgb(0 0 0 / 10%) 3px 3px 20px"
        borderRadius={4}
      >
        <AuthForm onSuccess={onSuccess} />
      </Box>
    </Flex>
  );
};

export default LoginPage;
